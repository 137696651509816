import axios from "axios";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { KEY_STORAGE, API_URL } from "../utils/Constant";


const TokenValidate = async () => {
  const { getItem, setItem, removeItem } = useLocalStorage();
  let access_token = getItem(KEY_STORAGE.USER_TOKEN);
  let refresh_token = getItem(KEY_STORAGE.USER_REFRESH_TOKEN);

  let accessTokenExpireTime;

  try {
    accessTokenExpireTime = jwt_decode(access_token).exp;
  } catch (error) {
    throw new Error("logout-app");
  }
  if (moment.unix(accessTokenExpireTime) - moment(Date.now()) < 10000) {
    //generating new accessToken
    let refreshTokenExpireTime;

    try {
      refreshTokenExpireTime = jwt_decode(refresh_token).exp;
    } catch (error) {
      throw new Error("logout-app");
    }
    if (moment.unix(refreshTokenExpireTime) - moment(Date.now()) > 10000) {
      return new Promise((resolve, reject) => {

        axios            
          .post(API_URL + "/api/token/refresh/", {
            refresh: refresh_token,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            if (!res?.data?.access) {
              throw new Error("logout-app");
            } else {
              //refreshed the access token
              const { access } = res?.data;
              setItem(KEY_STORAGE.USER_TOKEN, access);
              resolve(access);
            }
          })
          .catch((err) => {
            throw new Error("logout-app");
          });
      });
    } else {
      //refreshToken expired
      removeItem(KEY_STORAGE.USER_REFRESH_TOKEN);
      removeItem(KEY_STORAGE.USER_TOKEN);
      alert("Your session has expired, please login again.");
      //navigate("/logout");
    }
    return access_token;
  }
  return access_token;
};
export default TokenValidate;
