import axios from "axios";
import TokenValidate from "./TokenValidate";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { KEY_STORAGE } from "../utils/Constant";

//Request Interceptor
axios.interceptors.request.use(
  async (config) => {
    const { getItem } = useLocalStorage();
    if (config.url.includes("/login")) return config;
    if (config.url.includes("api/token/")) return config;
    if (config.url.includes("api/token/refresh/")) return config;
    if (config.url.includes("api/token/verify/")) return config;
    try {
      await TokenValidate();
      config.headers["Authorization"] = `Bearer ${getItem(
        KEY_STORAGE.USER_TOKEN
      )}`;
      if (config.headers["Content-Type"] !== "multipart/form-data") {
        config.headers["Content-Type"] = "application/json";
      }
      return config;
    } catch (error) {
      window.location.href = "/login";
      return Promise.reject(error);
    }
  },
  async (error) => {
    const request = error.config;

    if (error.response && error.response.status === 401 && !request._retry) {
      request._retry = true;

      try {
        // Actualizar el token solo una vez
        await TokenValidate();
        
        // Reintentar la solicitud original con el nuevo token
        request.headers["Authorization"] = "Bearer " + localStorage.getItem(KEY_STORAGE.USER_TOKEN);
        request.headers["Content-Type"] = "application/json";
        return axios(request);
      } catch (tokenError) {
        // Si hay un error al actualizar el token, manejar según sea necesario
        return Promise.reject(tokenError);
      }
    }

    return Promise.reject(error);
  }
);

// Response Interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const request = error.config; //this is actual request that was sent, and error is received in response to that request
    if (error.response && error.response.status === 401 && !request._retry) {
      request._retry = true;
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem(KEY_STORAGE.USER_TOKEN);
      axios.defaults.headers.common["Content-Type"] = "application/json";
      return axios(request);
    }
    return Promise.reject(error);
  }
);

export default axios;
