import React, { useState } from "react";
import "./galery.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { GaleryService } from "./galery.service";
import { useNavigate } from "react-router-dom";
import { validateHttp } from "../../utils/Utils";
import { API_URL } from "../../utils/Constant";
import MessageManager from "../commons/message/messageManager";
import { TYPE_MESSAGE } from "../../utils/Constant";

export default function ItemSelect(props) {
  const { nameFile, item, onChange } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [itemImage, setItemImage] = useState(item);
  const [errorMessages, setErrorMessages] = useState([]);
  const [messageManagerState, setMessageManagerState] = useState({
    show: false,
    type: TYPE_MESSAGE.INFO,
    title: "",
    messageText: "",
  });

  const saveImage = async (file) => {
    try {
      let result = "";
      if (itemImage) {
        result = await GaleryService("POST", itemImage, file);
      } else {
        const saveData = {
          name: nameFile,
          state: true,
        };

        result = await GaleryService("POST", saveData, file);
      }

      handleMessage(true, null, t("messageUpdaetImage"), TYPE_MESSAGE.SUCCESS);

      setItemImage(result);
    } catch (error) {
      if (error.message === "exit") {
        navigate("/");
      } else if (error) {
        setErrorMessages(error);
      }
    }
  };

  const handleMessage = (show, title, messageText, type) => {
    setMessageManagerState({
      show: show,
      type: type,
      title: title,
      messageText: messageText,
    });
    setTimeout(() => {
      setMessageManagerState({
        show: false,
        type: TYPE_MESSAGE.INFO,
        title: "",
        messageText: "",
      });
    }, 3000);
  };

  const handleShowImage = async (event) => {
    try {
      const status = event.target.checked;
      const data = { ...itemImage, state: status };
      setItemImage(data);
      await GaleryService("POST", data);
      handleMessage(
        true,
        null,

        status ? t("messageShowImage") : t("messageNotShowImage"),

        TYPE_MESSAGE.SUCCESS
      );
    } catch (error) {
      if (error.message === "exit") {
        navigate("/");
      } else if (error) {
        setErrorMessages(error);
      }
    }
  };

  useEffect(() => {
    setItemImage(item);
  }, [item]);

  return (
    <div className="item-imagen-select">
      <MessageManager
        show={messageManagerState.show}
        title={messageManagerState.title}
        messageText={messageManagerState.messageText}
        type={messageManagerState.type}
        onClose={() => {
          setMessageManagerState({
            show: false,
            type: TYPE_MESSAGE.INFO,
            title: "a",
            messageText: "b",
          });
        }}
      />
      <input
        type="file"
        id={nameFile}
        mame={nameFile}
        onChange={async (event) => {
          try {
            await saveImage(event.target.files[0]);
          } catch (error) {
            console.error(error);
          }
        }}
      />
      <label for={nameFile} className="container-label-image">
        {!item && !itemImage && (
          <>
            <FontAwesomeIcon icon={faCamera} className="profile-images" />
            <br />
            <div>{t("uploadImage")} </div>{" "}
          </>
        )}
        {itemImage && (
          <img
            alt="not fount"
            width={"250px"}
            src={
              validateHttp(itemImage.image)
                ? itemImage.image
                : API_URL + itemImage.image
            }
          />
        )}
      </label>
      <div className="show-image-perfiles">
        {!itemImage && <div className="block-check"></div>}
        <label For={itemImage?.pk ? itemImage.pk : "temp"}>{t("post")}</label>
        <input
          type="checkbox"
          id={itemImage?.pk ? itemImage.pk : "temp"}
          checked={itemImage?.state}
          onChange={async (event) => {
            handleShowImage(event);
          }}
        />
      </div>
    </div>
  );
}
